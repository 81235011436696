import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props} locale='zh-CN'>
      <Seo title='Our Team' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='团队成员'
          subheader='Defi.to汇集了一群深耕DeFi领域的优质创作者，团队平均拥有5年以上区块链领域从业经验，更深谙热门DeFi项目运作逻辑以及经济模型。通过不断创作输出高质量DeFi知识科普、DeFi操作教程、DeFi数据分析以及DeFi前沿事件追踪，帮助读者降低DeFi门槛，推动DeFi从小众走向主流。如果您也和我们一样熟悉DeFi世界并对其充满热情，欢迎您加入我们成为Defi.to网站编辑一员。'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
